<template>
<div class="message-board">
  <el-row class="row">
    <el-col :span="2" class="key">发信人</el-col>
    <el-col :span="5" class="val">{{data.maker_name}}</el-col>
    <el-col :span="2" class="key">发信人角色</el-col>
    <el-col :span="5" class="val">{{data.maker_type}}</el-col>
    <el-col :span="2" class="key">发信时间</el-col>
    <el-col :span="8" class="val">{{data.maker_time}}</el-col>
  </el-row>
  <el-row class="row">
    <el-col :span="2" class="key">收信人</el-col>
    <el-col :span="5" class="val">{{data.receiving_name	}}</el-col>
    <el-col :span="2" class="key">收信人角色</el-col>
    <el-col :span="5" class="val">{{data.receiving_type	}}</el-col>
    <el-col :span="2" class="key">收信时间</el-col>
    <el-col :span="8" class="val">{{data.receiving_time}}</el-col>
  </el-row>
  <h4>留言内容</h4>
  <article>{{data.content}}</article>


  <div class="back-button">
    <el-button size="small" @click="$router.back()">返回</el-button>
  </div>
</div>
</template>

<script>
export default {
	_config:{"route":{"path":"show","meta":{"title":"查看"}}},
  data(){
    return {
      data:{}
    }
  },
  methods:{
    getData(){
      this.$_axios2.get("/api/message/index/details",{
        logic:1,
        params:{
          id:this.$route.query.id
        }
      }).then(res=>{
        this.data = res.data;
      })
    }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.message-board{
  padding: 24rem;
  .row{
    font-size: 14rem;
    margin-bottom: 40rem;
    .key{
      color: #999;
      padding-right: 10rem;
    }
  }
  h4{
    margin: 40rem 0;
    font-size: 18rem;
  }
  article{
    margin-bottom: 40rem;
    font-size: 14rem;
  }
}
</style>
